<template>
    <div v-if="!editMode" @dblclick="openEdit">{{ row.item.description }}
        <font-awesome-icon @click="openEdit" icon="edit"/>
    </div>
    <div v-else-if="editMode">
        <b-input-group>
            <b-input v-model="editValue" size="sm"></b-input>
            <b-input-group-append>
                <b-button size="sm" @click="save" variant="warning">
                    <font-awesome-icon  icon="save"/>
                </b-button>
            </b-input-group-append>
            <b-input-group-append>
                <b-button size="sm" @click="closeEdit">
                    <font-awesome-icon icon="times"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </div>
    <div v-else-if="loading">
        <b-overlay :show="loading" no-wrap></b-overlay>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    data() {
        return {
            loading: false,
            editMode: false,
            editValue: null,
        }
    },
    methods: {
        openEdit() {
            this.editValue = this.row.item.description
            this.editMode = true
        },
        closeEdit() {
            this.editMode = false
        },
        save() {
            this.loading = true
            this.$store.dispatch('BankTransfers/save', {id: this.row.item.id, description: this.editValue})
                .then((response) => {
                    this.refreshTable()
                })
                .finally(() => {
                    this.loading = false
                    this.closeEdit()
                })


        }
    }
}
</script>